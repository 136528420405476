import React from "react";
import Layout from "../components/Layout/Layout";
import Hero from "../components/FAQs/Hero.js";
import Questions from "../components/FAQs/Questions.js";

export default function Faqs() {
  return (
    <Layout>
      <Hero />
      <Questions />
    </Layout>
  );
}
