import React from "react";
import { Link } from "gatsby";
import Collapsible from "react-collapsible";
import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import "../../styles/components/FAQs/Questions.scss";
const faqsData = [
  {
    question: "What grade levels do you support?",
    answer: " We currently support grades 6 through 12, including Calculus.",
  },
  {
    question: "Can we print tests and assignments with DeltaMath?",
    answer:
      " Teachers on the INTEGRAL tier have the ability to print assignments and assessments to PDF for accessibility and classroom use. Printable answer keys are accessible for each problem set. Additionally, teachers have the option of adding a QR code that links back to full online solutions.",
  },
  {
    question: "Is there any way for teachers to see student work?",
    answer:
      " Teachers on the INTEGRAL tier have the ability to assign and collect student notes. Images may be uploaded directly from a student’s computer or submitted from a phone using a QR code displayed on their screen.",
  },
  {
    question:
      "Does my teacher account have access to all grade levels/problems?",
    answer: " Yes. Every teacher account has access to all grade levels.",
  },
  {
    question: "What state standards is DeltaMath aligned to?",
    answer:
      " Our premium licenses (PLUS/INTEGRAL) offer alignment to standards for all 50 US states, DC, and (forthcoming) Canada. Performance reports against standards are available with an INTEGRAL school or district license.",
  },
  {
    question: "Is there any limit to the number of assignments?",
    answer:
      " No. With a DeltaMath Teacher account, you can create unlimited assignments. With DeltaMath PLUS or INTEGRAL, you can also assign videos, create tests, select specific problem sub-types, and/or create your own problem.",
  },
  {
    question: "What kind of data does a teacher have access to?",
    answer:
      " Teachers can see every problem students have attempted or seen, including student answers. Timestamps detail when students were solving, their last actions, and the amount of time spent on each problem. Teachers can also view and export aggregate student completion grades. With DeltaMath PLUS or INTEGRAL, teachers can see when students started a timed test or assignment, how long students worked on that test or assignment, and also track if students watched help videos.",
  },
  {
    question: "Does DeltaMath offer integrations?",
    answer:
      " We offer integrations in our INTEGRAL tier, both for teacher accounts and for site licenses. INTEGRAL teacher accounts offer Canvas or Schoology integration, and INTEGRAL site licenses offer Canvas, Schoology, ClassLink, and Clever integrations.",
  },
  {
    question: "Can teachers share their tests and assignments?",
    answer:
      " Yes. Teachers can share tests and assignments via the Copy Assignments feature under the Tools menu. Admins using an INTEGRAL site license will also have the ability to push assessments for diagnostic purposes.",
  },
  {
    question:
      "I'm a student, and I'm having trouble getting into my account. What should I do?",
    answer:
      " Contact your teacher. They should have the ability to see your email login and/or reset your password. If that doesn't work, they can reach out to us for next steps.",
  },
  {
    question: 'Why can\'t I "Sign in with Google"?',
    answer:
      "In order to use Google SSO, make sure that your browser is not in 'private' or 'incognito' mode. If you are not in incognito mode and see this warning, Google SSO may be blocked due to disabled third-party cookies in your browswer settings. To fix this in Chrome, open Settings and find the 'Privacy and security' page. Select 'Cookies and other site data', and make a selection that allows third-party cookies.",
    htmlElement: (
      <p id="google-sso-error">
        In order to use Google SSO, make sure that your browser is not in
        'private' or 'incognito' mode. If you are not in incognito mode and see
        this warning, Google SSO may be blocked due to disabled third-party
        cookies in your browser settings. To fix this in Chrome, open{" "}
        <b>Settings</b> and find the <i>Privacy and security</i> page. Select{" "}
        <i>Cookies and other site data</i>, and make a selection that allows
        third-party cookies.
      </p>
    ),
  },
  {
    question: 'Is DeltaMath compliant with FERPA/COPPA/my state, region, or district\'s specific data privacy laws/policies?',
    htmlElement: (
      <p>Yes. DeltaMath is fully compliant with FERPA, COPPA, and all local laws in both the United States and Canada concerning student data privacy. Please email <a href="mailto:support@deltamath.com">support@deltamath.com</a> if you need us to answer any questions or fill out any paperwork to demonstrate our compliance.</p>
    )
  }
];

function Questions() {
  return (
    <div className="container">
      <div className="questions-container">
        {faqsData.map((faq, index) => {
          let header = (
            <div className="collapsible-header">
              <span>{faq.question}</span>
              <span className="down-arrow">
                <RiArrowDownSFill className="dropDownArrow" />
              </span>
            </div>
          );
          return (
            <Collapsible trigger={header}>
              {faq.htmlElement ? faq.htmlElement : <p>{faq.answer}</p>}
            </Collapsible>
          );
        })}
      </div>

      <div className="faq-footer">
        <p className="content">
          Don't see your question?<br />Visit our <a href="https://help.deltamath.com" target="_blank" rel="noopener noreferrer">Help Center</a> or try <Link to="/contact">contacting us</Link>.
        </p>
      </div>
    </div>
  );
}

export default Questions;
